import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './api-utils';
import {
    CreateFolderRequest,
    DeleteFolderRequest,
    DetailedMessage,
    EmailSubject,
    EmailSubjectList,
    FeedbackRequest,
    Folder,
    FolderList,
    Message,
    MessageCount,
    MessageList,
    MessageListRequest,
    MessageRequest,
    MessageRequestList,
    MoveMailsToRecycleBinRequest,
    MoveToFolderRequest,
    RestoreMailsRequest,
    SaveDraftResponse,
    UpdateFolderRequest
} from '../@types/MessagingTypes';

const URL_API = '/business_webmsg/rest/v1';
const PARTIAL_LIST = 'PARTIAL-LIST';
export const UNREAD_COUNT = 'UNREAD-COUNT';
const ONE_MESSAGE = 'ONE_MESSAGE';
const LIST = 'LIST';

export const messagingApi = createApi({
    reducerPath: 'messagingApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    tagTypes: ['Messages', 'MessagesByFolder', 'Folders'],
    endpoints: builder => ({
        getUnreadMailCount: builder.query<MessageCount, MessageListRequest>({
            query: request => ({
                url: '/head/mails',
                method: 'POST',
                data: request
            }),
            providesTags: [{type: 'Messages', id: UNREAD_COUNT}]
        }),

        getUnreadImportantMailCount: builder.query<MessageCount, MessageListRequest>({
            query: request => ({
                url: '/head/important',
                method: 'POST',
                data: request
            }),
            providesTags: [{type: 'Messages', id: UNREAD_COUNT}]
        }),

        getMails: builder.query<Message[], MessageListRequest>({
            query: request => ({
                url: '/get/mails',
                method: 'POST',
                data: request
            }),
            providesTags: (result, messages, request) =>
                // is result available?
                result
                    ?
                    [
                        ...result.map(({id}) => ({type: 'Messages', id} as const)),
                        {type: 'Messages', id: PARTIAL_LIST},
                        {type: 'MessagesByFolder', id: request.folder},
                    ]
                    // an error occurred, but we still want to refetch this query when `{ type: 'Messages', id: 'PARTIAL-LIST' }` is invalidated
                    :
                    [{type: 'Messages', id: PARTIAL_LIST}],
            transformResponse: (response: MessageList) => response?.messages
        }),

        getMail: builder.query<DetailedMessage, MessageRequest>({
            query: request => ({
                url: '/get/mail',
                method: 'POST',
                data: request
            }),
            providesTags: [{type: 'Messages', id: ONE_MESSAGE}],
        }),

        getSubjects: builder.query<Array<EmailSubject>, void>({
            query: () => ({
                url: '/webpro/subjects/get',
                method: 'POST'
            }),
            transformResponse: (response: EmailSubjectList) => response.webProEmailSubjects
        }),

        deleteMails: builder.mutation<void, MessageRequestList>({
            query: request => ({
                url: '/delete/mailList',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                /*...request?.messageIds?.map(({ id }) => ({ type: 'Messages', id } as const)),
                { type: 'Messages', id: 'PARTIAL-LIST' },*/
                {type: 'Messages', id: UNREAD_COUNT}
            ]
        }),
        moveMailsToRecycleBin: builder.mutation<void, MoveMailsToRecycleBinRequest>({
            query: request => ({
                url: '/folders/moveItemsToRecycleBin',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                {type: 'Messages', id: UNREAD_COUNT},
                {type: 'Messages', id: PARTIAL_LIST}
            ]
        }),
        sendMail: builder.mutation<void, { formData: FormData }>({
            query: ({formData}) => ({
                url: '/webpro/email/send',
                method: 'POST',
                data: formData
            }),
        }),
        saveDraft: builder.mutation<SaveDraftResponse, { formData: FormData }>({
            query: ({formData}) => ({
                url: '/saveDraft',
                method: 'POST',
                data: formData
            }),
            invalidatesTags: (result, error, request) => [{type: 'Messages', id: ONE_MESSAGE}]
        }),
        sendFeedback: builder.mutation<void, FeedbackRequest>({
            query: request => ({
                url: '/sendFeedback',
                method: 'POST',
                data: request
            })
        }),

        getFolders: builder.query<Folder[], void>({
            query: () => ({
                url: '/folders/get',
                method: 'POST'
            }),
            providesTags: result =>
                result
                    ?
                    [
                        ...result.map(({name}) => ({type: 'Folders', name} as const)),
                        {type: 'Folders', name: LIST}
                    ]
                    :
                    [{type: 'Folders', name: LIST}],
            transformResponse: (response: FolderList) => response?.folderList
        }),
        createFolders: builder.mutation<void, CreateFolderRequest>({
            query: request => ({
                url: '/folders/create',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                {type: 'Folders', name: LIST}
            ]
        }),
        deleteFolders: builder.mutation<void, DeleteFolderRequest>({
            query: request => ({
                url: '/folders/delete',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                {type: 'Folders', name: LIST},
                {type: 'Messages', id: UNREAD_COUNT},
            ]
        }),
        updateFolder: builder.mutation<void, UpdateFolderRequest>({
            query: request => ({
                url: '/folders/rename',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                {type: 'Folders', name: LIST},
                {type: 'Messages', id: UNREAD_COUNT},
            ]
        }),
        moveToFolder: builder.mutation<void, MoveToFolderRequest>({
            query: request => ({
                url: '/folders/moveItemsToFolder',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                {type: 'Folders', name: LIST},
                {type: 'Messages', id: UNREAD_COUNT},
                {type: 'Messages', id: PARTIAL_LIST}
            ]
        }),
        restoreMails: builder.mutation<void, RestoreMailsRequest>({
            query: request => ({
                url: '/folders/restoreItems',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                {type: 'Folders', name: LIST},
                {type: 'Messages', id: UNREAD_COUNT},
                {type: 'Messages', id: PARTIAL_LIST}
            ]
        }),
      controlBackendMail: builder.mutation<void, void>({
        query: () => ({
          url: '/control/backend',
          method: 'POST',
          data: {}
        })
      })
    })
});

export const {
    useGetUnreadMailCountQuery,
    useGetUnreadImportantMailCountQuery,
    useGetMailsQuery,
    useGetMailQuery,
    useGetSubjectsQuery,
    useDeleteMailsMutation,
    useMoveMailsToRecycleBinMutation,
    useSaveDraftMutation,
    useSendMailMutation,
    useSendFeedbackMutation,
    useGetFoldersQuery,
    useCreateFoldersMutation,
    useDeleteFoldersMutation,
    useUpdateFolderMutation,
    useMoveToFolderMutation,
    useRestoreMailsMutation,
    useControlBackendMailMutation
} = messagingApi;
